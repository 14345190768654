const hasURLSearchParams =
  typeof window !== 'undefined' && typeof URLSearchParams !== 'undefined'

export const getParam = (key: string) => {
  if (!hasURLSearchParams) return null
  const searchParams = new URLSearchParams(window.location.search)
  return searchParams.get(key)
}

export const getParamObject = (key: string) => {
  const param = getParam(key)
  if (!param) return null

  const entries = new URLSearchParams(decodeURIComponent(param))
  const result = {}

  for (const [key, value] of entries) {
    result[key] = value
  }

  return result
}
