import React from 'react'
import { BuilderComponent } from '@builder.io/react'
import { CheckoutProvider } from '~/context/checkout'
import 'cms/builder/registry'

interface Props {
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  header: any
  /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
  footer: any
}

const Layout: React.FC<React.PropsWithChildren<Props>> = ({
  header,
  children,
  footer,
}) => {
  return (
    <>
      <header className="header fixed-top pt-0">
        <BuilderComponent model="header" content={header} />
      </header>

      <main role="main">
        <CheckoutProvider>{children}</CheckoutProvider>
      </main>

      <footer>
        <BuilderComponent model="footer" content={footer} />
      </footer>
    </>
  )
}

export default Layout
