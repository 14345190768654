export const CHECKOUT_DATA_KEY_ADDRESS = 'pl-address-data'
export const CHECKOUT_DATA_KEY_REPORT = 'pl-report-data'

export const CHECKOUT_STEP_CHECKOUT = 'CHECKOUT_STEP_CHECKOUT'
export const CHECKOUT_STEP_DEFAULT = 'CHECKOUT_STEP_DEFAULT'
export const CHECKOUT_STEP_CHECKOUT_COMPLETE = 'CHECKOUT_STEP_CHECKOUT_COMPLETE'
export const CHECKOUT_STEP_INTERSTITIAL = 'CHECKOUT_STEP_INTERSTITIAL'
export const CHECKOUT_STEP_REPORT_PREVIEW = 'CHECKOUT_STEP_REPORT_PREVIEW'

export const CHECKOUT_PATHS = {
  [CHECKOUT_STEP_CHECKOUT]: 'checkout',
  [CHECKOUT_STEP_DEFAULT]: '',
  [CHECKOUT_STEP_INTERSTITIAL]: 'interstitial',
  [CHECKOUT_STEP_REPORT_PREVIEW]: 'report',
}

export const URL_PROMO_CODES_INITIAL = 'pl-promo-codes-initial'
export const URL_PROMO_CODES_OUTSTANDING = 'pl-promo-codes-outstanding'
